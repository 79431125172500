import React, { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Column from 'components/Column';
import Panel from 'components/Panel';
import StyledHeading from 'typography/StyledHeading';
import PageSection from 'components/PageSection';
import styled from 'styled-components';
import Amenities from 'components/Amenities';
import HouseRules from 'components/HouseRules';
import ShowLocation from 'components/ScrollToLink';
import { getPropertyContent } from 'clients/content';
import { getExperiments } from 'clients/experiment';
import { isClientError } from 'clients/error';
import {
    IContext,
} from 'clients/content';
import Grid from 'components/Grid';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import AvailabilityFooter from 'components/CheckAvailabilityFooter';
import { useState } from 'react';
import AvailabilityFromInput from 'components/ListingAvailabilityCard';
import CalendarWidget from 'components/CalendarWidget';
import SlideUpPanel from 'components/HOCS/SlideUpPanel';
import typographyGenerator from 'typography/generator';
import GuestWidget from 'components/GuestWidget';
import Map from 'components/Map';
import Carousel from 'components/Carousel';
import CancellationWidget from 'components/CancellationPolicyWidget';
import Spacer from 'components/Spacer';
import { Avatar, Help } from 'components/PageHeaderChips';
import PropertyStayInformation from 'components/PropertyStayInformation';
import MetaTagsHead from 'components/MetaTagsHead';
import shareImg from 'public/icons/share.svg';
import { IContent } from 'types/content';
import { getAppliedDiscount } from 'lib/discounts';
import { IStayAtom } from 'state/atoms/stay';
import { calculatePricing } from 'components/CalendarWidget/mapping/stay-price';
import DirectDiscountInlineAlert from 'components/DiscountInlineAlert';
import { useSendDiscountPageLoadEvent } from 'hooks/tracking/discount';
import { IExperiments } from 'types/experiment';
import RatingHeadline from 'components/Rating/RatingHeadline';
import { InlineAlert } from '@travelnest/hui';
import { getReviewStats } from 'lib/rating';
import ReviewPreviewPanel from 'components/Rating/ReviewPreviewPanel';
import { parseNumberQuery, parseStringQuery } from 'lib/query';
import { datesSequential, totalGuestsLessThanCapacity } from 'lib/stay';
import { buildLOS } from 'lib/los';
import { getLocalTemporalDate } from 'components/CalendarWidget/mapping/date-helpers';
import { IBookingFeeResponse, getBookingFeeRate } from 'clients/bookingfee';
import { getFxRate } from 'clients/fx';

const PropertyDescription = styled.div`
  ${(props) => typographyGenerator('body', props.theme)}
  overflow: hidden;
  white-space: break-spaces;
`;

const Container = styled.div`
  margin-top: 16px;
`;

const DesktopView = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    display: flex;
    position: sticky;
    top: ${(props) => props.theme.gutters.medium};
  };
  @media (min-width: ${(props) => props.theme.breakpoints.x_large}) {
    top: ${(props) => props.theme.gutters.x_large};
  };
`;

const MobileView = styled.div`
  display: flex;
  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

const TripHeading = styled.label`
  ${(props) => typographyGenerator('body_longform', props.theme)};
  color: ${(props) => props.theme?.colors.LM_N100};
  font-weight: 700;
`;

const TripDetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TripDetailsMobile = styled.div`
    padding: 20px 16px;
`;

const MapPanel = styled(Panel)`
  padding: 0px !important;
  overflow: hidden;
  margin-bottom: 16px;
  border: 1px solid ${(props) => props.theme?.colors.LM_N30};
`;

const ImageContainer = styled(Panel)`
  width: 100%;
  height: 100%;
  min-height: calc(100vw/4*3);
  max-height: calc(100vw/4*3);
  border-radius: 16px;
  overflow: hidden;
  transform: translateZ(0);
  padding: 0px 0px !important;
  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    min-height: calc(100vw/4*3);
    max-height: calc(100vw/4*3);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    min-height: calc(100vw/16*9);
    max-height: calc(100vw/16*9);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    min-height: calc(100vw/16*9);
    max-height: calc(100vw/16*9);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.x_large}) {
    min-height: calc(100vw/235*1);
    max-height: 578px;
  }
`;

const CarouselColumn = styled(Column)`
    max-width:100%;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ShareButton = styled.div`
  width: 44px;
  height: 44px;
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    display: flex;
    cursor: pointer;
  }
`;

const ShareImage = styled(shareImg)`
`;

export const getServerSideProps = async (ctx: IContext) => {
    try {
        const content = await getPropertyContent(ctx.query.propertyId);
        const ownerId = content.property.owner_id;
		const experiments = await getExperiments(ownerId);
        const bookingFee = await getBookingFeeRate(ctx.query.propertyId);
		// This is only temporary until we support USD in Mangopay
		// We need to make the guest pay in GBP if the property is priced in USD
		let fxRateUSDtoGBP;
		if (content.pricing[0].currency === 'USD') {
			fxRateUSDtoGBP = await getFxRate('USD', 'GBP');
		}
        return { props: { content, experiments, bookingFee, fxRateUSDtoGBP} };
    } catch (e) {
        console.error('Error: ', e);
        if (isClientError(e)) {
            return { notFound: true };
        } else {
            return {
                props: { error: 'Internal server error' }
            };
        }
    }
};


interface IPageProps {
    content: IContent
	experiments: IExperiments,
    bookingFee: IBookingFeeResponse,
	fxRateUSDtoGBP: number
}

const Page: React.FC<IPageProps> = ({ content, bookingFee, experiments, fxRateUSDtoGBP }) => {
    // Router
    const router = useRouter();

    const { propertyId, check_in: queryCheckIn, check_out: queryCheckOut, adults: queryAdults, children: queryChildren, affiliate: queryAffiliate, discount } = router.query;
    const { property, availability = [], pricing = [], images = [], discounts = [], reviews } = content;

    const adultsInt = parseNumberQuery(queryAdults);
    const childrenInt = parseNumberQuery(queryChildren);

    const capacityDefaults = !isNaN(adultsInt) &&
        !isNaN(childrenInt) &&
        totalGuestsLessThanCapacity(property.capacity, adultsInt, childrenInt) ? {
            adults: adultsInt,
            children: childrenInt
        } : {
            adults: property.capacity,
            children: 0
        };

    const startOnString = parseStringQuery(queryCheckIn);
    const endOnString = parseStringQuery(queryCheckOut);
    const dateDefaults = startOnString &&
        endOnString &&
        datesSequential(startOnString, endOnString) ? {
            startOn: startOnString,
            endOn: endOnString
        } : {
            startOn: '',
            endOn: ''
        };

    // STATE: Data
    const [stay, setStayAtom] = useState<IStayAtom>({
        adults: content.property.capacity,
        children: 0,
        infants: 0,
        isDateSelected: false,
        hasAvailability: false,
    });
    const [stayDates, setStayDates] = useState<{
        startOn: string,
        endOn: string
    }>(dateDefaults);
    const [stayGuests, setStayGuests] = useState<{
        adults: number,
        children: number,
    }>(capacityDefaults);
    const [affiliate] = useState<string | undefined>(
        parseStringQuery(queryAffiliate)
    );

    // STATE: Rendering
    const showCal = useState(false);
    const [calendarComponentVisible, setCalendarVisible] = showCal;
    const [bookingComponentVisible, setBookingVisible] = useState(false);
    const showGuest = useState(false);
    const [guestComponentVisible, setGuestVisible] = showGuest;
    const showCancellation = useState(false);
    const [cancellationPolicyVisable, setCancellationPolicyVisable] = showCancellation;

    // REFS: Map
    const mapRef = useRef() as any;
    const reviewsRef = useRef() as any;

    const showMobileBookingPicker = () => {
        setBookingVisible(!bookingComponentVisible);
    };

    const showCalendarPicker = () => {
        setCalendarVisible(!calendarComponentVisible);
    };

    const showGuestPicker = () => {
        setGuestVisible(!guestComponentVisible);
    };

    const showCancellationInfo = () => {
        setCancellationPolicyVisable(!cancellationPolicyVisable);
    };

    const didPressShare = () => {
        const shareData = {
            title: property.name,
            text: property.long_description.slice(0, 150),
            url: `https://direct.travelnest.com/properties/${propertyId}`
        }
        const canShareLink = navigator?.canShare ? navigator?.canShare(shareData) : false;

        if (canShareLink) {
            navigator.share(shareData);
        } else {
            navigator.clipboard.writeText(shareData.url);
        }
    }

    const appliedDiscount = getAppliedDiscount(discount, discounts);
    useSendDiscountPageLoadEvent(appliedDiscount);

    const guestPageLink = useCallback(() => {
        const { adults, children, startDate, endDate } = stay;
        let link = encodeURI(`/properties/${propertyId}/guest-details?adults=${adults}&children=${children}&check_in=${startDate}&check_out=${endDate}`);
        if (appliedDiscount) {
            link += `&discount=${appliedDiscount.discountCode}`
        }
        if (affiliate) {
            link += `&affiliate=${affiliate}`;
        }
        if (window) {
            window.location.href = link;
        }
    }, [propertyId, stay, appliedDiscount, affiliate]);

    const setStayDatesHandler = (e: { startOn: string, endOn: string }) => setStayDates({ startOn: e.startOn, endOn: e.endOn })
    const setStayGuestsHandler = (e: { adults: number, children: number }) => setStayGuests({ adults: e.adults, children: e.children })

    const fetchedFee = bookingFee.booking_fee_rate

    const los = buildLOS(
        availability,
        pricing,
        property.capacity,
        property.advance_bookings || 1,
        1,
        fetchedFee
    );
    const firstSelectableDate = getLocalTemporalDate().add({ days: property.advance_bookings }).toString();

    useEffect(() => {
        const pricedStay = calculatePricing(
            los,
            content.property.restrictions,
            appliedDiscount,
            content.pricing[0].currency,
            stayDates?.startOn ? new Date(stayDates.startOn) : null,
            stayDates?.endOn ? new Date(stayDates.endOn) : null,
			fxRateUSDtoGBP
        );
        setStayAtom({
            ...stayGuests,
            startDate: stayDates?.startOn,
            endDate: stayDates?.endOn,
            infants: 0,
            ...pricedStay
        })
    }, [stayDates, stayGuests, content, appliedDiscount, fxRateUSDtoGBP]);

    const showReviews = experiments?.direct_reviews === 'on' && reviews.reviews.length > 0;
    const discountIsInvalid = appliedDiscount?.discountType === 'fixed' && stay?.totalToPayToday! < 0;
    const reviewStats = getReviewStats(reviews.reviews);

    return (
        <>
            <MetaTagsHead property={property} images={images} />
            <CalendarWidget
                showWidgetState={showCal}
                setStayDates={setStayDatesHandler}
                los={los}
                firstSelectableDate={firstSelectableDate}
            />
            <SlideUpPanel
                show={bookingComponentVisible}
                handleCloseClick={showMobileBookingPicker}
            >
                <TripDetailsCard>
                    <TripDetailsMobile>
                        <TripHeading>Your trip</TripHeading>
                        {
                            appliedDiscount === undefined ?
                                <></> :
                                <>
                                    <Spacer vertical={16} />
                                    {
                                        discountIsInvalid
                                            ? <InlineAlert variant="negative" heading="Discount not applied" content="Discount exceeds the total price" />
                                            : <DirectDiscountInlineAlert discount={appliedDiscount} currency={pricing[0].currency} />
                                    }
                                </>
                        }
                        <Spacer vertical={16} />
                        <AvailabilityFromInput
                            availabilityTitle="Check availability"
                            reservationTitle="Save"
                            cancellationPolicy={property.cancellation.tn_cancellation_preference}
                            handleAvailabilityClick={showCalendarPicker}
                            handleReservationClick={showMobileBookingPicker}
                            handleGuestClick={showGuestPicker}
                            handleCancellationPolicyClick={showCancellationInfo}
                            stay={stay}
                            discountInvalid={discountIsInvalid}
                        />
                    </TripDetailsMobile>
                </TripDetailsCard>
            </SlideUpPanel>
            <GuestWidget showWidgetState={showGuest} maxGuests={property.capacity} stayGuestsState={[stayGuests, setStayGuestsHandler]} />
            <CancellationWidget show={showCancellation} cancellationPolicy={property.cancellation.tn_cancellation_preference} check_in={stay.startDate || ''} />
            <PageHeader leftItem={<Avatar name="Travelnest" image="/icons/travelnest-logo-nest.svg" link="https://travelnest.com" />} rightItem={<Help propertyId={property.id} />} />
            <Grid>
                <CarouselColumn>
                    <ImageContainer>
                        <Carousel images={images} maxImageSize={[Infinity, 800]} />
                    </ImageContainer>
                </CarouselColumn>
                <Column
                    columnSpanMedium={5}
                    columnSpanLarge={6}
                    columnSpanXLarge={7}
                    columnSpanXXLarge={8}
                >
                    {
                        showReviews &&
                        <>
                            <RatingHeadline total={5} filled={reviewStats.averageRating} reviewsRef={reviewsRef} />
                            <Spacer vertical={8} />
                        </>
                    }
                    <HeadingContainer>
                        <StyledHeading type="h1">{property.name}</StyledHeading>
                        <ShareButton onClick={didPressShare}><ShareImage /></ShareButton>
                    </HeadingContainer>
                    <ShowLocation component={mapRef}>
                        {`${property.city}, ${property.country}`}
                    </ShowLocation>
                    <Panel>
                        <PropertyStayInformation
                            guestMaxLimit={property.capacity}
                            bedrooms={property.bedrooms}
                            beds={property.beds}
                            bathrooms={property.bathrooms}
                        />
                        <PageSection>
                            <PropertyDescription>
                                {property.long_description}
                            </PropertyDescription>
                        </PageSection>
                        <PageSection minHeight={8 * 16} alwaysExpand={property.formatted_amenities.length <= 8}>
                            <Amenities amenities={property.formatted_amenities} />
                        </PageSection>
                    </Panel>
                    <Container>
                        <Column
                            columnSpanMedium={5}
                            columnSpanLarge={6}
                            columnSpanXLarge={7}
                            columnSpanXXLarge={8}
                        >
                            {
                                showReviews &&
                                <>
                                    <ReviewPreviewPanel
                                        reviews={reviews.reviews}
                                        averageRating={reviewStats.averageRating}
                                        ratingScoreCount={reviewStats.ratingScoreCount}
                                        reviewsRef={reviewsRef}
                                    />
                                    <Spacer vertical={16} />
                                </>
                            }
                            <MapPanel ref={mapRef}>
                                <Map coordinates={[property.latitude, property.longitude]} />
                            </MapPanel>
                            <Panel>
                                <HouseRules
                                    houseRules={property.restrictions}
                                    checkIn={property.check_in}
                                    checkOut={property.check_out}
                                />
                            </Panel>
                        </Column>
                    </Container>
                </Column>
                <Column columnSpanLarge={6} columnSpanXLarge={5} columnSpanXXLarge={4}>
                    <DesktopView>
                        <Panel>
                            <TripDetailsCard>
                                <TripHeading>Your trip</TripHeading>
                                {
                                    appliedDiscount === undefined ?
                                        <></> :
                                        <>
                                            <Spacer vertical={16} />
                                            {
                                                discountIsInvalid
                                                    ? <InlineAlert variant="negative" heading="Discount not applied" content="Discount exceeds the total price" />
                                                    : <DirectDiscountInlineAlert discount={appliedDiscount} currency={pricing[0].currency} />
                                            }
                                        </>
                                }
                                <Spacer vertical={16} />
                                <AvailabilityFromInput
                                    availabilityTitle="Check availability"
                                    reservationTitle="Reserve"
                                    cancellationPolicy={property.cancellation.tn_cancellation_preference}
                                    handleAvailabilityClick={showCalendarPicker}
                                    handleReservationClick={guestPageLink}
                                    handleGuestClick={showGuestPicker}
                                    handleCancellationPolicyClick={showCancellationInfo}
                                    stay={stay}
                                    discountInvalid={discountIsInvalid}
                                />
                            </TripDetailsCard>
                        </Panel>
                    </DesktopView>
                </Column>
            </Grid>
            <MobileView>
                <AvailabilityFooter
                    handleAvailabilityClick={showMobileBookingPicker}
                    handleReservationClick={guestPageLink}
                    isVisible={true}
                    availabilityTitle="Check availability"
                    reservationTitle="Reserve"
                    stay={stay}
                />
            </MobileView>
            <PageFooter marginBottom={56} />
        </>
    );
};

export default Page;
