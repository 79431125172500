import React, { useState, MouseEvent, KeyboardEvent } from 'react';
import Panel from 'components/Panel';
import Breakdown from '../Breakdown';
import ReviewList from '../ReviewList';
import styled from 'styled-components';
import { Button } from '@travelnest/hui';
import { IReview } from 'types/content';
import ReviewModal from '../ReviewModal';
import moment from 'moment';

export interface IReviewPreviewPanelProps {
    reviews: IReview[]
    averageRating: number,
    ratingScoreCount: number[],
    reviewsRef?: React.RefObject<HTMLDivElement>;
}

const StyledButtonFlex = styled.span`
    display: flex;
    justify-content: center;

    button {
        flex-grow: 0;
    }
`

const ReviewPreviewPanel: React.FC<IReviewPreviewPanelProps> = ({ reviews, averageRating, ratingScoreCount, reviewsRef }) => {
    const [focusedReviewId, setFocusedReviewId] = useState<number | null>(null);

    const scrollToReview = (reviewId: number) => {
        setFocusedReviewId(reviewId);
    };

    const sortedReviews = reviews.sort((a, b) =>
        moment(b.reviewCreatedAt).isBefore(a.reviewCreatedAt) ? -1 : 1
    )

    const reviewsWithRefsToPreview = sortedReviews
        .filter((r) => r.primaryContent)
        .sort((a, b) =>
            moment(b.reviewCreatedAt).isBefore(a.reviewCreatedAt) ? -1 : 1
        )
        .slice(0, 4);

    return (
        <>
            {
                <ReviewModal
                    reviews={reviews}
                    averageRating={averageRating}
                    ratingScoreCount={ratingScoreCount}
                    jumpTo={focusedReviewId}
                    handleCloseEvent={() => setFocusedReviewId(null)}
                />
            }
            <Panel ref={reviewsRef}>
                <Breakdown
                    buckets={ratingScoreCount}
                    averageRating={averageRating}
                />
                <ReviewList
                    reviews={
                        reviewsWithRefsToPreview.map((review) => ({
                            id: review.id,
                            channelId: review.channelId,
                            primaryContent: review.primaryContent,
                            reviewCreatedAt: review.reviewCreatedAt,
                            reviewerName: review.reviewerName,
                            preview: true,
                            onPreviewClick: (_e: MouseEvent<HTMLElement> | KeyboardEvent) => scrollToReview(review.id)
                        }))
                    }
                />

                <StyledButtonFlex>
                    <Button
                        data-testid="show-all-reviews-button"
                        size="small"
                        variant="primary"
                        fill="light"
                        text="Show all reviews"
                        type="button"
                        onClick={(_e) => setFocusedReviewId(-1)}
                    />
                </StyledButtonFlex>
            </Panel>
        </>
    )
}

export default ReviewPreviewPanel;
