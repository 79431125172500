import styled from 'styled-components';
import StarTag from '../StarTag';
import AdjectiveTag from '../AdjectiveTag';
import { theme } from '@travelnest/hui';
import { KeyboardEvent, useCallback } from 'react';

interface IRatingHeadlineProps {
    /**
     * The total number of stars to calculate a rating from
    */
    total: number;
    /**
     * The number of filled stars to display
    */
    filled: number;
    /**
     * Unique element ref for reviews section
     */
    reviewsRef?: React.RefObject<HTMLDivElement>;
}

const Container = styled.span`
    display: inline-flex;
    gap: 8px;
    cursor: pointer;
    &:focus {
        outline: 2px solid ${theme.colors.BB80};
        border-radius: 8px;
    }
`;

const RatingHeadline: React.FC<IRatingHeadlineProps> = (props) => {
    const scrollIntoView = useCallback(() => {
        if (props.reviewsRef?.current) {
            props.reviewsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [props.reviewsRef]);
    return (
        <Container
            tabIndex={0}
            onClick={scrollIntoView}
            onKeyUp={(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    scrollIntoView();
                }
            }}
        >
            <StarTag {...props} data-testid="star-tag" />
            <AdjectiveTag {...props} data-testid="adjective-tag" />
        </Container>
    )
}

export default RatingHeadline;
