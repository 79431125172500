import React from 'react';
import Head from 'next/head';
import { IImage, IProperty } from 'types/content';

export const urlForImageTag = (image: IImage, fallbackImage = 'https://direct.travelnest.com/favicon.png') => {
    const smallest = image?.formats['original']?.x_dimension || Infinity;
    let ogImage = image?.original || fallbackImage;
    Object.values(image?.formats || []).forEach(format => { if (format.x_dimension < smallest) ogImage = format.url; });

    return ogImage
}

export interface IMetaTagsHead {
    property: IProperty;
    images: IImage[];
}

const MetaTagsHead: React.FC<IMetaTagsHead> = ({ property, images }) => {
    const { name = 'Travelnest Direct', long_description = 'Direct bookings' } = property;

    const ogDescription = long_description.slice(0, 150); // limit the max text length for the og link
    const ogImage = urlForImageTag(images[0]);

    return (<Head>
        <title>{name}</title>
        <meta property="og:title" content={name} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
        <meta name="twitter:card" content="summary_large_image" />
    </Head>);
}

export default MetaTagsHead;
