import { ReactNode } from 'react';
import styled from 'styled-components';
import Pin from 'public/icons/PropertyIcons/pin.svg';
import typographyGenerator from 'typography/generator';

const Container = styled.div`
    ${(props => typographyGenerator('button', props.theme))}
    margin-top: 8px;
    a {
        ${(props => typographyGenerator('body', props.theme))}
        color: ${(props) => props.theme?.colors.BB100};
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
        margin-bottom: 16px;
        width: max-content;
        cursor: pointer;
    }
`;
interface IScrollToLink {
    children: ReactNode | string;
    component: any;
};

const ScrollToLink: React.FC<IScrollToLink> = ({ children, component }) => {
    const scrollToComponent = (evt: any) =>{
        evt.preventDefault();
        component.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); 
    }
    return (
        <Container>
            <a onClick={scrollToComponent}><Pin />{children}</a>
        </Container>
    )
};

export default ScrollToLink;
