import Tag from 'components/Tag';

const RATING_EXCELLENT = 'Excellent';
const RATING_VERY_GOOD = 'Very good'
const RATING_GOOD = 'Good';

interface IAdjectiveTag {
    /**
 * The total number of stars to calculate a rating from
 */
    total: number;
    /**
     * The number of filled stars to display
     */
    filled: number;
}

const AdjectiveTag: React.FC<IAdjectiveTag> = ({ total, filled }) => {
    const percentFilled = filled / total;
    const qualityLabelText = percentFilled >= 0.8 ? RATING_EXCELLENT :
        percentFilled >= 0.6 ? RATING_VERY_GOOD :
            percentFilled >= 0.4 ? RATING_GOOD :
                null

    return <>
        {
            qualityLabelText &&
            <Tag color="green" transparent={false}>
                {qualityLabelText}
            </Tag>
        }
    </>
}

export default AdjectiveTag;
