import { StarRating, theme } from '@travelnest/hui';
import styled from 'styled-components';

interface IScoredStarRatingProps {
  /**
   * The average rating score for the property.
   */
  filled: number;
  /**
   * The maximum rating a property can score.
   */
  total: number;
}


const Container = styled.span`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const Score = styled.h4`
  font-size: ${theme.typography.RWB110.fontSize};
  font-family: ${theme.typography.RWB110.fontFamily};
  font-weight: ${theme.typography.RWB110.fontWeight};
  letter-spacing: ${theme.typography.RWB110.letterSpacing};
  line-height: 125%;
`;

const ScoredStarRating: React.FC<IScoredStarRatingProps> = ({ total, filled }) =>
      <Container data-testid="scored-star-rating">
        <Score>{filled.toFixed(1)}</Score>
        <StarRating total={total} filled={filled} />
      </Container>

export default ScoredStarRating;
