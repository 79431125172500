import styled from 'styled-components';
import typographyGenerator from 'typography/generator';
import { getCurrencySymbol, calculatePricePerNight } from 'state/atoms/pricing';
import { IStayAtom, hasSelectedStay, formatStayDates } from 'state/atoms/stay';
import StyledButton from 'components/StyledButton';
import StyledText from 'typography/StyledText';

const Visible = styled.div<{
  isVisible: boolean;
}>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const Container = styled.div`
    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
        visibility: hidden };

    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
        visibility: hidden };

    @media (min-width: ${(props) => props.theme.breakpoints.xx_large}) {
        visibility: hidden };

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 16px;
    position: fixed;
    width: 100%;
    height: 56px;
    left: 0px;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.75);
    border-top: 0.5px solid ${(props) => props.theme?.colors.LM_N30};
    backdrop-filter: blur(10px);
    z-index: 800;
`;

const LeftSideDiv = styled.div`
  height: 100%;
  color: ${(props) => props.theme?.colors.LM_N100};
  a {
    color: ${(props) => props.theme?.colors.BB100};
  }
`;

const RightSideDiv = styled.div`
  a {
    color: ${(props) => props.theme?.colors.BB100};
  }
  @media (max-width: 440px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      margin-top: 16px;
    }
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PricePerNightContainer = styled.div`
  display: flex;
`;


const PricePerNight = styled(StyledText)`
  font-weight: 700;
  color: ${(props) => props.theme?.colors.LM_N100};
`;

const NightText = styled.div`
  ${(props) => typographyGenerator('label', props.theme)}
`;

const GuestDetailsContainer = styled.div`
  ${(props) => typographyGenerator('label', props.theme)}
  text-decoration-line: underline;
  color: ${(props) => props.theme?.colors.LM_N100};
  cursor: pointer;
`;

const AddDatesDefault = styled(StyledText)`
  display:flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const SmallButton = styled(StyledButton)`
  height: 44px !important;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 24px;
  min-width: 100px;
`;

interface IAvailabilityFooter {
  availabilityTitle: string;
  reservationTitle: string;
  defaultText?: string;
  isVisible: boolean;
  handleAvailabilityClick: () => void;
  handleReservationClick: () => void;
  stay: IStayAtom
}

const AvailabilityFooter: React.FC<IAvailabilityFooter> = ({
  availabilityTitle,
  reservationTitle,
  defaultText = 'Add dates for price',
  handleAvailabilityClick,
  handleReservationClick,
  isVisible,
  stay
}) => {
  let buttonText = availabilityTitle;

  if (hasSelectedStay(stay)) {
    buttonText = reservationTitle;
  }
  const mappedCurrency = getCurrencySymbol(stay.currency!);
  const [pricePerNight] = calculatePricePerNight(
    stay.startDate!,
    stay.endDate!,
    stay.baseStayPrice!
  );

  return (
    <Visible isVisible={isVisible}>
      <Container>
        <LeftSideDiv>
          {hasSelectedStay(stay) ? (
            <DetailsContainer>
              <PricePerNightContainer>
                <PricePerNight type="label">
                  {`${mappedCurrency} ${pricePerNight}`}
                </PricePerNight>
                <NightText>/night</NightText>
              </PricePerNightContainer>
              <GuestDetailsContainer onClick={handleAvailabilityClick}>
                {stay.adults + stay.children} guests ·{' '}
                {formatStayDates(stay.startDate!, stay.endDate!)}
              </GuestDetailsContainer>
            </DetailsContainer>
          ) : (
            <AddDatesDefault type="label">{defaultText}</AddDatesDefault>
          )}
        </LeftSideDiv>
        <RightSideDiv>
          <SmallButton
            title={buttonText}
            type="button"
            disabled={false}
            handleClick={() => {
              if (hasSelectedStay(stay)) {
                handleReservationClick();
              } else {
                handleAvailabilityClick();
              }
            }}
          />
        </RightSideDiv>
      </Container>
    </Visible>
  );
};

export default AvailabilityFooter;
