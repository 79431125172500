import styled from 'styled-components';
import Spacer from 'components/Spacer';
import RoundButton from 'components/RoundButton';
import PlusIcon from 'public/icons/map/plus.svg';
import MinusIcon from 'public/icons/map/minus.svg';
import ExpandIcon from 'public/icons/map/expand.svg';

const MapZoomControls = styled.div`
  position: absolute;
  right: 16px;
  bottom: 24px;
`;

const MapButton = styled(RoundButton)`
  width: 36px;
  height: 36px;
  max-width: 36px;
  background-color: white !important;
  box-shadow: 0px 1px 4px rgba(10, 24, 43, 0.05),
    0px 2px 8px rgba(10, 24, 43, 0.08);
  border-radius: 100px;
`;

const MapExpandControls = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

interface IMapControls {
  map: any;
  coordinates: [number, number];
}

const MapControls: React.FC<IMapControls> = ({ map, coordinates }) => {
  return (
    <>
      <MapZoomControls>
        <MapButton
          buttonImage={<PlusIcon />}
          handleClick={() => map.setZoom(map.getZoom() + 1)}
          disabled={false}
        />
        <Spacer vertical={4} />
        <MapButton
          buttonImage={<MinusIcon />}
          handleClick={() => map.setZoom(map.getZoom() - 1)}
          disabled={false}
        />
      </MapZoomControls>
      <MapExpandControls>
        <MapButton
          buttonImage={<ExpandIcon />}
          handleClick={() => {
            window &&
              window.open(
                `https://www.google.com/maps/place/@${coordinates[0]},${
                  coordinates[1]
                },${map.getZoom()}z`,
                '_blank'
              );
          }}
          disabled={false}
        />
      </MapExpandControls>
    </>
  );
};

export default MapControls;
