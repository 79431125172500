
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';

const LazyImg = styled.img<{ loaded: boolean }>`
    transition: opacity 300ms ease-out; 
    opacity: ${({ loaded }) => loaded ? 1 : 0};
`;

interface ILazyImage {
    src: string;
    srcSet?: string;
    errorSrc?: string;
    lazy?: boolean;
}

const LazyImage: React.FC<ILazyImage> = ({ srcSet, src, errorSrc, lazy = true, ...props }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);
    const imgRef = useRef<any>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsImageLoaded(true);
                    observer.unobserve(imgRef?.current);
                }
            },
            { threshold: 0.5 }
        );

        observer.observe(imgRef.current);
    }, []);

    return (
        <LazyImg
            ref={imgRef}
            src={hasError ? errorSrc : src}
            srcSet={hasError ? '' : srcSet}
            onLoad={() => {
                setIsImageLoaded(true)
            }}
            onError={() => setHasError(true)}
            loaded={isImageLoaded || !lazy}
            loading={lazy ? 'lazy' : 'eager'}
            {...props}
        />
    )
}

export default LazyImage;
