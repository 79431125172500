/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from '@travelnest/hui';
import { IReview } from 'types/content';
import CloseImage from 'public/icons/close.svg';
import RoundButton from 'components/RoundButton';
import ReviewList from '../ReviewList';
import Breakdown from '../Breakdown';
import { getReviewStats } from 'lib/rating';

export interface IReviewModalProps {
  /**
   * The reviews to be rednered within the modal
   */
  reviews: IReview[],
  /**
   * The rating of a property to be displayed as an average star rating
   */
  averageRating: number,
  /**
   * Count buckets for the rating breakdown
   */
  ratingScoreCount: number[],
  handleCloseEvent: () => void;
  /**
   * The id of the review that we should scroll to
  */
  jumpTo?: number | null;
}

/**
 * Handles rendering over the main page content and any responsive animations.
 * Should potentially be abstracted and used to replace the slide-up modal component.
 */
const Container = styled.div<{ open: boolean, opened: boolean }>`
  display: absolute;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 1000;
  width: 100%;
  height: 100vh;


  @media (min-width: ${() => theme.breakpoints.sm.min}) {
    ${(props) => props.open && `
      animation: 300ms fadeIn;
      background-color: ${theme.colors.LM_NU70T};
      visibility: visible;
    `}

    ${(props) => !props.open && `
      background-color: transparent;
      visibility: hidden;
    `}

    @keyframes fadeIn {
      0% {
        visibility: hidden;
        background-color: transparent;
      }
      1% {
        visibility: visible;
      }
      100% {
        background-color: ${theme.colors.LM_NU70T};
      }
    }
  };

  @media (max-width: ${() => theme.breakpoints.sm.min}) {
    ${(props) => props.open && `
      background-color: ${theme.colors.LM_NU70T};
      top: 0;
      height: 100%;
      animation: 1s slideIn;
    `}

    ${(props) => !props.open && `
      visibility: hidden;
      background-color: transparent;
      top: 100%;
      height: 0;
      ${props.opened && 'animation: 500ms slideOut'} ;
    `}

    @keyframes slideIn {
      0% {
        background-color: transparent;
        top: 100%;
        height: 0vh;
      }
      50% {
        background-color: transparent;
        top: 0;
        height: 100vh;
      }
      80% {
        background-color: ${theme.colors.LM_NU70T};
      }
    }

    @keyframes slideOut {
      0% {
        top: 0;
        height: 100vh;
      }
      99% {
        visibility: visible;
      }
      100% {
        top: 100%;
        height: 0;
        visibility: hidden;
      }
    }
  };
`;

const ModalFlex = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: ${theme.colors.LM_NU0};
  position: relative;
  display: flex;
  flex-direction: column;

  border-radius: 16px 16px 0 0;
  max-height: 100%;
  box-shadow: 0px 2px 8px 0px rgba(10, 24, 43, 0.08), 0px 1px 4px 0px rgba(10, 24, 43, 0.05);

  @media (min-width: ${() => theme.breakpoints.sm.min}) {
    border-radius: 16px;
    max-height: 80vh;
    max-width: 790px;
  };
`;

const ModalTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const ModalTitle = styled.div`
  font-size: ${theme.typography.RWB110.fontSize};
  font-family: ${theme.typography.RWB110.fontFamily};
  font-weight: ${theme.typography.RWB110.fontWeight};
  letter-spacing: ${theme.typography.RWB110.letterSpacing};
  line-height: 125%;
  color: ${theme.colors.typography.heading};
`;

const ModalBody = styled.div`
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  @media (min-width: ${theme.breakpoints.sm.min}) {
    padding: 16px 24px;
  };
`

const ReviewsListSingleColumn = styled(ReviewList)`
    grid-template-columns: 1fr;
`

const ReviewModal: React.FC<IReviewModalProps> = ({ reviews, jumpTo, handleCloseEvent }) => {
  const { ratingScoreCount, averageRating } = getReviewStats(reviews);
  const [opened, setOpened] = useState(false);
  const mainContentRef = useRef<HTMLDivElement>(null);

  /**
   * Attaches a listener to the document to close the modal when escape is pressed.
   */
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleCloseEvent();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleCloseEvent]);
  /**
   * Prevents flash-of-unwanted-animation on page load; styled-components
   * use the opened prop to only play anim after modal first opened.
  */
  useEffect(() => {
    setOpened(opened || jumpTo !== null)
  }, [jumpTo, opened]);

  /**
   * Scrolls to the review specified as the focus by the jumpTo
   * prop on load. No smoothness applied as this looks visually
   * awful combined with fade/slide CSS anims.
  */
  useEffect(() => {
    if (jumpTo === -1) {
      mainContentRef.current?.scrollIntoView();
    }
    document.body.style.overflow = jumpTo !== null ? 'hidden' : '';
  }, [jumpTo])

  return (<Container open={jumpTo != null} opened={opened}>
    <ModalFlex data-testid="review-modal" onClick={handleCloseEvent}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalTitleBar>
          <ModalTitle>Guest reviews</ModalTitle>
          <RoundButton
            buttonImage={<CloseImage />}
            disabled={false}
            handleClick={handleCloseEvent}
          />
        </ModalTitleBar>
        <ModalBody>
          <span ref={mainContentRef}></span>
          <Breakdown
            buckets={ratingScoreCount}
            averageRating={averageRating}
          />
          <ReviewsListSingleColumn
            reviews={reviews.map((review) => ({
              id: review.id,
              channelId: review.channelId,
              title: review.title,
              primaryContent: review.primaryContent,
              secondaryContent: review.secondaryContent,
              reviewCreatedAt: review.reviewCreatedAt,
              reviewerName: review.reviewerName,
              rating: review.rating / 20,
              preview: false,
              onPreviewClick: () => { },
            }))
            }
            jumpTo={jumpTo !== -1 ? jumpTo : null}
          />
        </ModalBody>
      </ModalContent>
    </ModalFlex>
  </Container>)
}

export default ReviewModal;
