import styled from 'styled-components';
import { IBathroom, IBed, IBedroom } from 'types/content';
import typographyGenerator from 'typography/generator';

const PropertyCapacityInformation = styled.div`
    ${(props) => typographyGenerator('body', props.theme)}
    overflow: hidden;
    color: ${(props => props.theme?.colors.LM_N70)};
    margin-bottom: ${(props) => props.theme.gutters.small};
    @media (min-width: ${(props => props.theme.breakpoints.small)}) {
        margin-bottom: ${(props) => props.theme.gutters.small};
      }
    @media (min-width: ${(props => props.theme.breakpoints.medium)}) {
        margin-bottom: ${(props) => props.theme.gutters.medium};
      }
    @media (min-width: ${(props => props.theme.breakpoints.xx_large)}) {
        margin-bottom: ${(props) => props.theme.gutters.xx_large};
      };
`;

interface IStayCapacityInfo {
    guestMaxLimit: number;
    bedrooms: IBedroom[];
    beds: IBed[];
    bathrooms: IBathroom[];
}

const PropertyStayInformation: React.FC<IStayCapacityInfo> = ({ guestMaxLimit, bedrooms, beds, bathrooms }) => {

    // Need to filter out beds in common spaces to get correct number of bedrooms
    const numBedrooms = bedrooms.filter(b => b.room_type !== 'common_spaces').length;
    const numBeds = beds.length;
    const numBathrooms = bathrooms.length;

    const guestText = guestMaxLimit > 1 ? 'guests' : 'guest';
    const bedroomsText = numBedrooms > 1 ? 'bedrooms' : 'bedroom';
    const bedsText = numBeds > 1 ? 'beds' : 'bed';
    const bathroomText = numBathrooms > 1 ? 'bathrooms' : 'bathroom';

    return (
        <PropertyCapacityInformation>
            {guestMaxLimit} {guestText} · {numBedrooms} {bedroomsText} · {numBeds} {bedsText} · {numBathrooms} {bathroomText}
        </PropertyCapacityInformation>
    )
}

export default PropertyStayInformation;
