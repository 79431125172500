export const parseStringQuery = (query: any): string | undefined => {
    // If it is a string return it
    if (typeof query === 'string')
        return query;
    // If it is an array return the first item
    if (Array.isArray(query))
        return query[0];
    // Otherwise return undefined
    return undefined;
};

export const parseNumberQuery = (query: any): number | typeof NaN => {
    // If it is a string return it
    if (typeof query === 'string')
        return parseInt(query);
    // If it is an array return the first item
    if (Array.isArray(query))
        return parseInt(query[0]);
    // Otherwise return undefined
    return NaN;
};
