import { CSSObject } from 'styled-components';
import { TagColor } from '.';
import { theme } from '@travelnest/hui';

export const colorMap: Record<TagColor, (_transparent: boolean) => CSSObject> =
	{
		blue: (transparent) =>
			transparent
				? {
						backgroundColor: theme.colors.BB30T,
						color: theme.colors.BB200,
				  }
				: {
						backgroundColor: theme.colors.BB30,
						color: theme.colors.BB200,
				  },

		green: (transparent) =>
			transparent
				? {
						backgroundColor: theme.colors.GR30T,
						color: theme.colors.GR200,
				  }
				: {
						backgroundColor: theme.colors.GR30,
						color: theme.colors.GR200,
				  },
		pink: (transparent) =>
			transparent
				? {
						backgroundColor: theme.colors.PI30T,
						color: theme.colors.PI200,
				  }
				: {
						backgroundColor: theme.colors.PI30,
						color: theme.colors.PI200,
				  },
		purple: (transparent) =>
			transparent
				? {
						backgroundColor: theme.colors.PU30T,
						color: theme.colors.PU200,
				  }
				: {
						backgroundColor: theme.colors.PU30,
						color: theme.colors.PU200,
				  },
		yellow: (transparent) =>
			transparent
				? {
						backgroundColor: theme.colors.YE30T,
						color: theme.colors.YE200,
				  }
				: {
						backgroundColor: theme.colors.YE30,
						color: theme.colors.YE200,
				  },
		teal: (transparent) =>
			transparent
				? {
						backgroundColor: theme.colors.TE30T,
						color: theme.colors.TE200,
				  }
				: {
						backgroundColor: theme.colors.TE30,
						color: theme.colors.TE200,
				  },
		red: (transparent) =>
			transparent
				? {
						backgroundColor: theme.colors.RE30T,
						color: theme.colors.RE200,
				  }
				: {
						backgroundColor: theme.colors.RE30,
						color: theme.colors.RE200,
				  },
	};

export const getTagStyles = (
	color: TagColor = 'green',
	transparent: boolean = false
) => colorMap[color](transparent);
