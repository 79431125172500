import styled from 'styled-components';
import RenderIcons from 'components/DisplayIcons';
import Column from 'components/Column';
import { mapHouseRules } from './house-rules';
import { useMemo } from 'react';
import { IRestriction } from 'types/content';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(1fr, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 32px;
    @media (min-width: ${(props => props.theme.breakpoints.medium)}) {
        grid-column-gap: 24px;
      }
`;

const HouseRulesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    width: 100%;
    line-break: strict;
    line-height: 24px;
    -webkit-line-clamp: 6;
`;

export interface IHouseRulesProps {
    houseRules: IRestriction[];
    checkIn: string;
    checkOut: string;
};

const HouseRules: React.FC<IHouseRulesProps> = ({ houseRules, checkIn, checkOut }) => {
    const mappedHouseRules = useMemo(() => mapHouseRules(houseRules, checkIn, checkOut), [houseRules, checkIn, checkOut]);

    return (
        <Grid>
            <Column columnSpanLarge={6} columnSpanXLarge={8} columnSpanXXLarge={12} columnSpanDefault={12}>
                <HouseRulesContainer>
                    <RenderIcons iconLabelList={mappedHouseRules} />
                </HouseRulesContainer>
            </Column>
        </Grid>
    )
};

export default HouseRules;
