import { IReview } from 'types/content';

// ratings are stored as a normalised value between 1 and 100
// we want to display this as a number of stars.
export const RATING_TO_STARS_MULTIPLIER = 0.05;

const getAverage = (values: number[]) => {
  const sum = values.reduce((a, b) => a + b, 0);
  return sum / values.length || 0;
};

export const getReviewStats = (reviews: IReview[]) => {
  const averageRating =
    getAverage(reviews.map((r) => r.rating)) * RATING_TO_STARS_MULTIPLIER;

  const ratingScoreCount = [0, 0, 0, 0, 0];
  reviews.forEach((review) => {
    ratingScoreCount[
      Math.round(review.rating * RATING_TO_STARS_MULTIPLIER) - 1
    ] += 1;
  });

  return {
    ratingScoreCount,
    averageRating,
  };
};
