import { theme } from '@travelnest/hui';
import styled, { css } from 'styled-components';

// Main container
export const Container = styled.article<{ preview: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${theme.colors.LM_NU10};
  gap: 16px;
  border-radius: 12px;

  ${({ preview }) => preview && `
    &:hover {
    background-color: ${theme.colors.LM_NU20};
    cursor: pointer;
    }
    &:focus {
      background-color: ${theme.colors.LM_NU30};
      outline: 2px solid ${theme.colors.BB80};
    }
    &:active {
      background-color: ${theme.colors.LM_NU30};
    }
  `};

`;

// Headline with channel and reviewer info
export const ReviewerDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;

export const ReviewerName = styled.span`
  font-size: ${theme.typography.IB90.fontSize};
  font-family: ${theme.typography.IB90.fontFamily};
  font-weight: ${theme.typography.IB90.fontWeight};
  letter-spacing: ${theme.typography.IB90.letterSpacing};
  line-height: 150%;
  color: ${theme.colors.typography.heading};
`;

export const ReviewChannelInfo = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ReviewerDetail = styled.div`
  font-size: ${theme.typography.IB80.fontSize};
  font-family: ${theme.typography.IB80.fontFamily};
  font-weight: 500;
  letter-spacing: ${theme.typography.IB80.letterSpacing};
  line-height: 150%;
  color: ${theme.colors.typography.body};
`;

// Core review content
export const CardBody = styled.div<{ preview: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  cursor: ${({ preview }) => preview ? 'pointer' : 'default'};
`;

export const ReviewTitle = styled.h5`
  font-size: ${theme.typography.RWB100.fontSize};
  font-family: ${theme.typography.RWB100.fontFamily};
  font-weight: ${theme.typography.RWB100.fontWeight};
  letter-spacing: ${theme.typography.RWB100.letterSpacing};
  line-height: 125%;
  color: ${theme.colors.typography.heading};
`;

interface ICardContentProps {
  /**
   * Whether or not to truncate the main card content
  */
  truncate: boolean;
}

export const ReviewText = styled.div<ICardContentProps>`
  --line-height: 1.5;
  --font-size: ${theme.typography.IB100.fontSize};
  line-height: var(--line-height);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  ${({ truncate }) =>
    truncate && css`
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `
  }
  font-size:  var(--font-size);
  font-family: ${theme.typography.IB100.fontFamily};
  font-weight: 400;
  letter-spacing: ${theme.typography.IB100.letterSpacing};
  color: ${theme.colors.typography.body};
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const ExpandButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: ${theme.typography.IB100.fontSize};
  font-family: ${theme.typography.IB100.fontFamily};
  font-weight: 400;
  letter-spacing: ${theme.typography.IB100.letterSpacing};
  line-height: 125%;
  color: ${theme.colors.typography.link};
`;
