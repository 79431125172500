import { InlineAlert } from '@travelnest/hui';
import { formatDiscountAmount } from 'lib/discounts';
import { IDirectDiscount } from 'types/content';

interface IDiscountInlineAlertProps {
    discount: IDirectDiscount | null
    currency: string
}

const DiscountInlineAlert: React.FC<IDiscountInlineAlertProps> = ({
    discount,
    currency
}) => {
    return discount === null ?
        <InlineAlert variant="negative" heading="Discount not applied" content="It could be expired or invalid" /> :
        <InlineAlert variant="informative" content={`${formatDiscountAmount(discount, currency)} discount will be applied at checkout`} />
};
export default DiscountInlineAlert;
