import React from 'react';
import styled from 'styled-components';
import StyledText from 'typography/StyledText';
import { CancellationPreferenceTypes, cancellationPolicyStrings } from 'lib/cancellationPolicy';

const infomationIcon = '/icons/information.svg';

const CancellationContainer = styled.div`
    margin: 16px 0px 0px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const HighlightedText = styled(StyledText)`
    color: ${(props) => props.theme?.colors.BB100};
    cursor: pointer;
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

interface ICancellationPolicy {
    cancellation_policy: CancellationPreferenceTypes;
    check_in: string;
}

const CancellationPolicy: React.FC<ICancellationPolicy> = ({ cancellation_policy, check_in }) => {
    const [policyText, dateHightlightText] = cancellationPolicyStrings(cancellation_policy, check_in);

    return (
        <CancellationContainer>
            <StyledText type="label"><>{policyText}</></StyledText>
            &nbsp;
            <HighlightedText type="label">{dateHightlightText}</HighlightedText>
            <Icon src={infomationIcon} />
        </CancellationContainer>
    )
}

export default CancellationPolicy;
