import styled from 'styled-components';
import { theme } from '@travelnest/hui';

import ReviewCard, { IReviewCardProps } from '../ReviewCard';
import { useEffect, useRef } from 'react';


export interface IReviewListHandle {
    scrollTo: (_reviewId: number) => void
}


export interface IReviewListProps {
    /**
     * The list of reviews that should be rendered
    */
    reviews: Omit<IReviewCardProps, 'ref'>[]
    /**
     * className prop for styles extension
    */
    className?: string;
    /**
     * list item to jumpTo on load
    */
    jumpTo?: number | null;
}

const Container = styled.span`
    display: grid;
    gap: 16px;
    align-content: start;
    /* Grid should display in two columns on lg+ screens */
    grid-template-columns: 1fr 1fr;
    /* Grid should only display a single column on sm/md screens */
    @media (max-width: ${theme.breakpoints.lg.max}) {
        grid-template-columns: 1fr;
    }
`;

// eslint-disable-next-line react/display-name
const ReviewList: React.FC<IReviewListProps> = (props) => {
    const createReviewRef = useRef({} as Record<number, any>)

    useEffect(() => {
        if (props.jumpTo) {
            createReviewRef.current[props.jumpTo]?.scrollIntoView();
        }
    }, [props.jumpTo])

    return <Container data-testid="review-list" className={props.className}>
        {
            props.reviews.map(
                (review, i) => {
                    return <ReviewCard
                        {...review}
                        key={i}
                        ref={(element) => createReviewRef.current[review.id] = element}
                    />
                }
            )
        }
    </Container>
}

export default ReviewList;
