import { StarRating } from '@travelnest/hui';
import Tag from 'components/Tag';

interface IStarTagProps {
    /**
     * The total number of stars to calculate a rating from
     */
    total: number;
    /**
     * The number of filled stars to display
     */
    filled: number;
}

const StarTag: React.FC<IStarTagProps> = (props) => {
    const { filled } = props;

    return <Tag color="yellow" transparent={false}>
        <span>{filled.toFixed(1)}</span>
        <StarRating {...props} />
    </Tag>

}

export default StarTag;
