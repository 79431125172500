import { IRestriction } from 'types/content';
import PersonCheck from '../../public/icons/PropertyIcons/person-check.svg';
import PersonCross from '../../public/icons/PropertyIcons/person-cross.svg';
import PetsAllowed from '../../public/icons/PropertyIcons/dog.svg';
import PetsNotAllowed from '../../public/icons/PropertyIcons/no-dog.svg';
import SmokingAllowed from '../../public/icons/PropertyIcons/smoking.svg';
import NoSmoking from '../../public/icons/PropertyIcons/no-smoking.svg';
import Party from '../../public/icons/PropertyIcons/party.svg';
import NoParty from '../../public/icons/PropertyIcons/no-party.svg';
import CheckIn from '../../public/icons/PropertyIcons/door-enter.svg';
import CheckOut from '../../public/icons/PropertyIcons/door-exit.svg';

const houseRuleMappings: { [houseRuleSlugName: string]: {
    displayName: string,
    icon: JSX.Element
}} = {
    'smoking_allowed': {
        displayName: 'Smoking allowed',
        icon: <SmokingAllowed />
    },
    'pets_allowed': {
        displayName: 'Pets allowed',
        icon: <PetsAllowed />
    },
    'children_allowed': {
        displayName: 'Children allowed',
        icon: <PersonCheck />
    },
    'infants_allowed': {
        displayName: 'Infants allowed',
        icon: <PersonCheck />
    },
    'groups_allowed': {
        displayName: 'Group bookings allowed',
        icon: <Party />
    },
    'no_smoking': {
        displayName: 'No smoking',
        icon: <NoSmoking />
    },
    'no_pets': {
        displayName: 'Pets not allowed',
        icon: <PetsNotAllowed />
    },
    'no_children': {
        displayName: 'Children not allowed',
        icon: <PersonCross />
    },
    'no_infants': {
        displayName: 'Infants not allowed',
        icon: <PersonCross />
    },
    'no_groups': {
        displayName: 'No parties / events allowed',
        icon: <NoParty />
    },
};

const ALLOWED_RESTRICTION_TYPE: { [key: string]: string } = {
    'smoking': 'smoking_allowed',
    'pets': 'pets_allowed',
    'children allowed': 'children_allowed',
    'infants allowed': 'infants_allowed',
    'group bookings': 'groups_allowed',
};

const NOT_ALLOWED_RESTRICTION_TYPE: { [key: string]: string } = {
    'smoking': 'no_smoking',
    'pets': 'no_pets',
    'children allowed': 'no_children',
    'infants allowed': 'no_infants',
    'group bookings': 'no_groups',
};

const getHouseRules = (restrictions: IRestriction[]) => {
    let defaultRestrictions: { [key: string]: boolean } = {
        'smoking': false,
        'pets': false,
        'children allowed': false,
        'infants allowed': false,
        'group bookings': false,
    };
    restrictions.forEach((r) => {
        if (r.allowed && r.restriction_type !== 'cleaning fee' && r.restriction_type !== 'tourist tax') {
            defaultRestrictions[r.restriction_type] = true;
        }
    });

    const houseRules: string[] = [];
    Object.keys(defaultRestrictions).map((key) => {
        if (defaultRestrictions[key]) {
            houseRules.push(ALLOWED_RESTRICTION_TYPE[key]);
        }
        else {
            houseRules.push(NOT_ALLOWED_RESTRICTION_TYPE[key]);
        }
    });

    return houseRules;
};

export const mapHouseRules = (restrictions: IRestriction[], checkIn: string, checkOut: string) => {

    const houseRulesList = getHouseRules(restrictions);
    let mappedHouseRules = houseRulesList.map(houseRuleSlugName => houseRuleMappings[houseRuleSlugName]).filter(hr => !!hr);
    mappedHouseRules.unshift(
        {
            displayName: `Check in: ${checkIn}`,
            icon: <CheckIn />
        },
        {
            displayName: `Check out: ${checkOut}`,
            icon: <CheckOut />
        }
    );
    return mappedHouseRules;
};
