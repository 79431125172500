import React from 'react';
import styled from 'styled-components';
import SlideUpPanel from 'components/HOCS/SlideUpPanel';
import Modal from 'components/HOCS/Modal';
import StyledHeading from 'typography/StyledHeading';
import StyledText from 'typography/StyledText';
import Spacer from 'components/Spacer';
import { cancellationPolicyStrings } from 'lib/cancellationPolicy';
import RoundButton from 'components/RoundButton';
import CloseImage from 'public/icons/close.svg';


const CancellationCardModal = styled.div`
  min-width: 558px;
  min-height: 180px;
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  max-width: 558px;
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    min-width: 100vw !important;
  }
`;

const MobileSlideUp = styled(SlideUpPanel)`
  display: flex;
  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

const ModalDesktop = styled(Modal)`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    display: flex;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
interface ICancellationWidget {
  show: any;
  cancellationPolicy: string;
  check_in: string;
}

const CancellationWidget: React.FC<ICancellationWidget> = ({ show, cancellationPolicy, check_in }) => {
  const [showPanel, setShowPanel] = show;
  const [policyDetails, highlight, footer] = cancellationPolicyStrings(cancellationPolicy as any, check_in);

  const ModelGuest: React.FC = () => (
    <CancellationCardModal>
      <Row>
        <StyledHeading type="h3" bold>Cancellation policy</StyledHeading>
        <RoundButton
          buttonImage={<CloseImage />}
          disabled={false}
          handleClick={() => setShowPanel(false)}
        />
      </Row>
      <Spacer vertical={20} />
      <Row>
        <StyledText type="body"><>{policyDetails}&nbsp;<strong>{highlight}</strong>.&nbsp;{footer}</></StyledText>
      </Row>
    </CancellationCardModal>
  );

  return (
    <>
      <MobileSlideUp show={showPanel} yPos="50" showCloseButton="hidden">
        <ModelGuest />
      </MobileSlideUp>
      <ModalDesktop
        show={showPanel}
        handleCloseClick={() => setShowPanel(false)}
      >
        <ModelGuest />
      </ModalDesktop>
    </>
  );
};

export default CancellationWidget;
