import { StarRating, theme } from '@travelnest/hui';
import styled from 'styled-components';
import AdjectiveTag from '../AdjectiveTag';

interface IAnnotatedStarRatingProps {
  /**
   * The average rating score for the property.
   */
  averageRating: number;
  /**
   * The maximum rating a property can score.
   */
  maximumRating: number;
  /**
 * The total number of ratings for a property
 */
  totalRatings: number;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Column = styled.span`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;

  @media (min-width: ${theme.breakpoints.xl.min}) {
    flex-direction: row;
    align-items: center;
  }
`;

const AnnotatedStars = styled.span`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

const AverageScore = styled.h2`
  font-size: ${theme.typography.RWB130.fontSize};
  font-family: ${theme.typography.RWB130.fontFamily};
  font-weight: ${theme.typography.RWB130.fontWeight};
  letter-spacing: ${theme.typography.RWB130.letterSpacing};
  line-height: 125%;

  @media (min-width: ${theme.breakpoints.md.min}) {
    font-size: ${theme.typography.RWB140.fontSize};
  }
`;

const ReviewCountDescription = styled.p`
  font-size: ${theme.typography.IR90.fontSize};
  font-family: ${theme.typography.IR90.fontFamily};
  font-weight: ${theme.typography.IR90.fontWeight};
  letter-spacing: ${theme.typography.IR90.letterSpacing};
  line-height: 125%;
  color: ${theme.colors.LM_NU70T};
`;


const AnnotatedStarRating: React.FC<IAnnotatedStarRatingProps> = ({ averageRating, maximumRating, totalRatings }) =>
  <Wrapper data-testid="annotated-star-rating-component">
    <Column>
      <AnnotatedStars>
        <AverageScore>{averageRating.toFixed(1)}</AverageScore>
        <StarRating total={maximumRating} filled={averageRating} />
      </AnnotatedStars>
      <AdjectiveTag total={maximumRating} filled={averageRating} />
    </Column>
    <Column>
      <ReviewCountDescription>{totalRatings} verified reviews</ReviewCountDescription>
    </Column>
  </Wrapper>


export default AnnotatedStarRating;
