import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IImage } from 'types/content';

import Controls from './Controls';
import LazyImage from './LazyImage';

const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	align-items: center;
	justify-content: space-between;
	flex-flow: column nowrap;
`;

const CarouselFrame = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	overflow: auto hidden;
	white-space: nowrap;
	scrollbar-width: none; /* firefox */
	::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
`;

const CarouselImage = styled(LazyImage)`
	min-width: 100%;
	min-height: 100%;
	object-fit: cover;
	scroll-snap-align: center;
	scroll-snap-stop: always;
`;

interface ICarousel {
	images: IImage[];
	maxImageSize?: [number, number];
	className?: string;
	lazy?: boolean;
}

const nextImage = (direction: 'prev' | 'next', scrollContainer: any) => {
	let scrollAmount = scrollContainer.clientWidth;
	scrollAmount = direction === 'prev' ? -scrollAmount : scrollAmount;
	scrollContainer.scrollBy({ left: scrollAmount });
};

const Carousel: React.FC<ICarousel> = ({
	images,
	className,
	maxImageSize = [Infinity, Infinity],
	lazy = true,
}) => {
	const scrollContainerRef = useRef() as any;
	const [index, setIndex] = useState(1);
	const imageCount = images.length;

	const scrollToNextImage = (direction: 'prev' | 'next') => {
		nextImage(direction, scrollContainerRef.current);
	};

	const updateImageCounter = () => {
		const currentIndex = Math.round(
			scrollContainerRef.current.scrollLeft /
				scrollContainerRef.current.clientWidth +
				1,
		);
		setIndex(currentIndex);
	};

	useEffect(() => {
		if (scrollContainerRef && scrollContainerRef.current) {
			scrollContainerRef.current.addEventListener(
				'scroll',
				updateImageCounter,
			);
		}
	}, [scrollContainerRef]);

	return (
		<Container className={className}>
			<CarouselFrame ref={scrollContainerRef} dir="ltr">
				{images.map((image, ndx) => {
					const srcset = Object.values(image.formats)
						.filter(
							({ name }) =>
								name !== 'original' &&
								Number(name.split('x')[0]) < maxImageSize[0] &&
								Number(name.split('x')[1]) < maxImageSize[1],
						)
						.map(
							({ url, name }) => `${url} ${name.split('x')[0]}w`,
						);
					return (
						<CarouselImage
							key={image.id}
							srcSet={srcset.join(',')}
							src={image.formats[image.original].url}
							lazy={ndx > 0 && lazy}
						/>
					);
				})}
			</CarouselFrame>
			<Controls
				handleNext={() => scrollToNextImage('next')}
				handlePrev={() => scrollToNextImage('prev')}
				counter={`${index}/${imageCount}`}
			/>
		</Container>
	);
};

export default Carousel;
