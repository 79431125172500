import { StarRating } from '@travelnest/hui';
import FlexGraph from '../../FlexGraph';
import styled from 'styled-components';
import AnnotatedStarRating from '../AnnotatedStarRating';

interface IBreakdownProps {
    /**
     * The total number of reviews in each star category.
    */
    buckets: number[];
    /**
     * The average review score for the property.
    */
    averageRating: number;
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 24px;
    padding-bottom: 24px;
`;

const Breakdown: React.FC<IBreakdownProps> = ({ buckets, averageRating }) => {
    const chunkedReviews = buckets.map((reviewCount, i) => ({
        count: reviewCount,
        annotation: <StarRating total={i + 1} filled={i + 1} shadow="NONE" range="TRUNCATED" size="small" />,
        altText: `${i + 1} star reviews`
    })).reverse();

    const totalRatings = buckets.reduce((prev, curr) => prev + curr, 0);
    const maximumRating = buckets.length;
    return (
        <Wrapper data-testid="review-breakdown">
            <AnnotatedStarRating averageRating={averageRating} maximumRating={maximumRating} totalRatings={totalRatings} />
            <FlexGraph items={chunkedReviews} />
        </Wrapper>
    );
}

export default Breakdown;
