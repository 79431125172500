import moment from 'moment';
import { decode } from 'he';

import ScoredStarRating from '../ScoredStarRating';
import ChannelIcon from 'components/ChannelIcon';
import React, { ForwardedRef, MouseEvent, forwardRef, useCallback, useState, KeyboardEvent } from 'react';
import { Channel } from 'types/content';
import { CardBody, Container, ExpandButton, ReviewChannelInfo, ReviewText, ReviewTitle, ReviewerDetail, ReviewerDetails, ReviewerName } from './styled';
import { capitalize } from 'lib/strings';
import Spacer from 'components/Spacer';

export interface IReviewCardProps {
    id: number;
    reviewerName: string;
    channelId: Channel;
    reviewCreatedAt: string;
    title?: string;
    primaryContent?: string;
    secondaryContent?: string;
    rating?: number;
    preview: boolean;
    onPreviewClick: (_e: MouseEvent<HTMLElement> | KeyboardEvent) => void;
    /**
     * ref for individual cards
    */
    ref: ForwardedRef<HTMLElement>;
}

export const MAX_PREVIEW_LINES = 3;

const buildReviewAge = (targetDateTime: string) => {
    const now = moment();
    const targetDate = moment(targetDateTime);
    const diffInDays = Math.abs(now.diff(targetDate, 'days'));
    const diffInMonths = Math.abs(now.diff(targetDate, 'months'));

    const formatTimePeriod = (str: string, n: number) => n > 1 ? `${n} ${str}s ago` : `${n} ${str} ago`;
    if (diffInDays < 31) {
        return formatTimePeriod('day', diffInDays)
    } else if (diffInMonths < 12) {
        return formatTimePeriod('month', diffInMonths)
    } else {
        const diffInYears = Math.abs(now.diff(targetDate, 'years'));
        return formatTimePeriod('year', diffInYears)
    }
}

// eslint-disable-next-line react/display-name
const ReviewCard = forwardRef(({ reviewerName, channelId, title, primaryContent, secondaryContent, rating, reviewCreatedAt, preview, onPreviewClick }: IReviewCardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [numberOfLines, setNumberOfLines] = useState(0);

    const contentRef = useCallback((node: HTMLElement | null) => {
        if (node !== null) {
            const lineHeight = parseInt(getComputedStyle(node).lineHeight, 10);
            const lines = Math.ceil(node.offsetHeight / lineHeight);
            setNumberOfLines(lines);
        }
    }, []);

    return <Container
        tabIndex={0}
        role="button"
        onClick={onPreviewClick}
        onKeyUp={(e) => {
            if (e.key === 'Enter') {
                onPreviewClick(e);
            }
        }}
        ref={ref}
        preview={preview}
    >
        <ReviewerDetails>
            <div>
                <ReviewerName>{capitalize(reviewerName)}</ReviewerName>
                <ReviewerDetail>{buildReviewAge(reviewCreatedAt)}</ReviewerDetail>
            </div>
            <ReviewChannelInfo>
                <ReviewerDetail>Verified on</ReviewerDetail>
                <ChannelIcon channel={channelId} />
            </ReviewChannelInfo>
        </ReviewerDetails>
        <CardBody data-testid="review-card-body" preview={preview}>
            {rating &&
                <ScoredStarRating total={5} filled={rating} />
            }
            {title &&
                <ReviewTitle>{decode(title)}</ReviewTitle>
            }
            <ReviewText ref={contentRef} truncate={preview}>
                {primaryContent && <p>
                    {decode(primaryContent)}
                </p>
                }
                {
                    primaryContent && secondaryContent &&
                    <Spacer vertical={12} />
                }
                {secondaryContent && <p>
                    {decode(secondaryContent)}
                </p>
                }
            </ReviewText>
            {
                (preview && numberOfLines >= 3) &&
                <ExpandButton>Show more</ExpandButton>
            }
        </CardBody>
    </Container>
})

export default ReviewCard;
