import { theme } from '@travelnest/hui';
import styled from 'styled-components';

interface IFlexGraphProps {
  /**
   * Bars that should be included on the graph
   */
  items: {
    annotation: React.ReactNode,
    count: number,
    altText: string,
  }[];
}

const StyledGraph = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;

  overflow: hidden;
`;

const LabelWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 4px ;
`;

const BarWrapper = styled.div`
  display: inline-flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 4px ;

  max-width: 300px;
`;

const Bar = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex: 1;
  max-height: 8px;

  overflow: hidden;
  background-color: ${theme.colors.LM_NU30};
  border-radius: 100px;
  width: 100%;
`;

interface IBarFilledSectionProps {
  /**
   * The percentage of the bar that should be filled. This should be a
   * number between zero and one.
   */
  fill: number
}

const BarFilledSection = styled.div<IBarFilledSectionProps>`
  display: flex;
  flex: ${({ fill }) => fill};
  height: 8px;

  background-color: ${theme.colors.YE100};
  border-radius: 100px 0 0 100px;
`;


const BarLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FlexGraph: React.FC<IFlexGraphProps> = ({ items }) => {
  const totalItems = items.reduce((total, item) => total + item.count, 0)

  const bars = items.map((item) => (
    <Bar
      key={item.count}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={totalItems}
      aria-valuenow={item.count}
      data-testid="bar"
    >
      <BarFilledSection fill={item.count / totalItems}></BarFilledSection>
    </Bar>
  ));


  const labels = items.map((item, index) => (
    <BarLabel
      key={item.count}
      aria-label={`Bar ${index + 1}: ${item.altText}`}
      data-testid="bar-label"
    >
      {item.annotation}
    </BarLabel>
  ));

  return <StyledGraph data-testid="flex-graph-component">
    <LabelWrapper>
      {labels}
    </LabelWrapper>
    <BarWrapper>
      {bars}
    </BarWrapper>
  </StyledGraph>
}

export default FlexGraph;
