import { ReactNode } from 'react';
import styled from 'styled-components';
import { theme } from '@travelnest/hui';
import { getTagStyles } from './styles';

export type TagColor =
    | 'blue'
    | 'green'
    | 'pink'
    | 'purple'
    | 'yellow'
    | 'teal'
    | 'red';

interface ITagProps {
    /**
     * The text and background color of the tag
     */
    color?: TagColor;
    /**
     * The fill or transparency level of the tag
     */
    transparent?: boolean;
    /**
     * Any ReactNodes that should be displayed as children
     */
    children: ReactNode;
}


const StyledTag = styled.span<ITagProps>`
	display: flex;
	flex-direction: row;
    justify-content: center;
    align-items: center;

	${(props) => getTagStyles(props.color, props.transparent)};

    border: none;
	border-radius: 8px;
	padding: 2px 8px;
    gap: 8px;

	width: auto;
	max-width: 100%;

    font-size: ${theme.typography.RWB100.fontSize};
    font-family: ${theme.typography.RWB100.fontFamily};
    font-weight: ${theme.typography.RWB100.fontWeight};
    letter-spacing: ${theme.typography.RWB100.letterSpacing};
    line-height: 125%;
`;

const Tag: React.FC<ITagProps> = (props) => {
    return <StyledTag {...props}>
        {props.children}
    </StyledTag>
}
export default Tag;
