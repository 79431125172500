import AirbnbIcon from 'public/icons/channel/airbnb.svg';
import BookingcomIcon from 'public/icons/channel/bookingcom.svg';
import { Channel } from 'types/content';

interface IChannelIconProps {
    /**
     * The name of the channel we should render an icon for.
    */
    channel: Channel;
}

const iconMap: Record<Channel, React.ReactElement> = {
    'airbnb.com': <AirbnbIcon />,
    'booking.com': <BookingcomIcon />,
}

const ChannelIcon: React.FC<IChannelIconProps> = ({ channel }) => iconMap[channel]

export default ChannelIcon;
