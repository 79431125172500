import styled from 'styled-components';
import RoundButton from 'components/RoundButton';
import LeftImage from 'public/icons/PhotoCarousel/left.svg';
import RightImage from 'public/icons/PhotoCarousel/right.svg';
import typographyGenerator from 'typography/generator';

const NextButton = styled(RoundButton)`
    position: absolute;
    top:50%;
    right: 24px;
    width: 100px;
    background-color: white;
`;

const PrevButton = styled(RoundButton)`
    position: absolute;
    top: 50%;
    left: 24px;
    background-color: white;
`;

const Counter = styled.div`
    ${(props) => typographyGenerator('body', props.theme)};
    display: flex;
    position: absolute;
    bottom: 16px;
    right: 24px;
    background-color: ${(props => props.theme?.colors.LM_N100)};
    width: 42px;
    height: 32px;
    color: white;
    border-radius: 4px;
    text-align: center;
    justify-content: center;
    align-items: center;
`;

const ButtonContainer = styled.div`
    display: none;
    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
        display: block;
    }
`;

interface IControls {
    handleNext: () => void;
    handlePrev: () => void;
    counter: string;
}

const Controls: React.FC<IControls> = ({ handleNext, handlePrev, counter }) => {

    return (
        <>
            <ButtonContainer>
                <PrevButton buttonImage={<LeftImage />} handleClick={handlePrev} />
                <NextButton buttonImage={<RightImage />} handleClick={handleNext} />
            </ButtonContainer>
            <Counter>{counter}</Counter>
        </>
    );
}


export default Controls;
