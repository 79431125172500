import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import defaultMapStyle from './style.json';
import MapControls from './Controls';

declare var google: any;
declare global {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface Window {
    google: any;
  }
}

const defaultMarkerIcon = '/icons/map/marker.svg';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapView = styled.div<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  min-height: 280px;
  transition: opacity 300ms ease-out;
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    min-height: 480px;
  }
`;


const loadGoogleMaps = (callback: any) => {
  const existingScript = document.getElementById('googleMaps');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_KEY}`;
    script.id = 'googleMaps';
    script.defer = true;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const createStyledMap = (
  mapOptions: any,
  markerOptions: any,
  mapStyle: any
) => {
  const styledMapType = new google.maps.StyledMapType(mapStyle, {
    name: 'Map',
  });
  let map = new window.google.maps.Map(
    document.getElementById('map'),
    mapOptions
  );
  map.mapTypes.set('styled_map', styledMapType);
  map.setMapTypeId('styled_map');
  new google.maps.Marker({ ...markerOptions, map: map });

  return map;
};

interface IMap {
  coordinates: [number, number];
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  mapStyle?: JSON;
  markerIcon?: { url: string, anchor: { x: number, y: number } };
  controls?: React.FC | null;
}

const Map: React.FC<IMap> = ({
  coordinates,
  zoom = 12,
  maxZoom = 16,
  minZoom = 8,
  mapStyle = defaultMapStyle,
  markerIcon = { url: defaultMarkerIcon, anchor: { x: 65, y: 65 } },
  controls = MapControls,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [map, setMap] = useState();
  const mapOptions = useMemo(
    () => ({
      center: { lat: coordinates[0], lng: coordinates[1] },
      zoom: zoom,
      maxZoom: maxZoom,
      minZoom: minZoom,
      mapTypeControlOptions: {
        mapTypeIds: ['styled_map'],
      },
      streetViewControl: false,
      zoomControl: controls ? false : true,
      mapTypeControl: false,
      fullscreenControl: controls ? false : true,
    }),
    [coordinates, zoom, maxZoom, minZoom, controls]
  );

  const markerOptions = useMemo(
    () => ({
      position: { lat: coordinates[0], lng: coordinates[1] },
      icon: markerIcon,
    }),
    [coordinates, markerIcon]
  );

  useEffect(() => {
    loadGoogleMaps(() => {
      if (window.google && !map) {
        setIsLoading(false);
        setMap(createStyledMap(mapOptions, markerOptions, mapStyle));
      }
    });
  }, []);

  return (
    <Container data-testid="map">
      <MapView data-testid="map-view" id="map" isLoading={isLoading}></MapView>
      {controls ? controls({ map, coordinates }) : null}
    </Container>
  );
};

export default Map;
